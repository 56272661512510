// https://stackoverflow.com/questions/56663785/invalid-hook-call-hooks-can-only-be-called-inside-of-the-body-of-a-function-com
// https://reactjs.org/warnings/invalid-hook-call-warning.html#duplicate-react
// https://stackoverflow.com/questions/61800182/how-to-route-angular-app-inside-nginx-container

import {useState, useEffect} from 'react';

import { IonApp, IonRouterOutlet, IonSplitPane
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';

import Login from './pages/Login';
import Logout from './pages/Logout';

import Home from './pages/Home';


import {useStorage,ProtectedRoute} from 'irc-common';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import magimeta from './magi-meta.json'

import {AppContext} from './global'
import React from 'react';
import GenericList from './pages/GenericList';
import { useRestGet } from './hooks/AdminHttp';
import Tool from './pages/Tool';



interface Json {[key:string]:any};


const App: React.FC = () => {

  const [data,setData] = useStorage("data");

  const [entities,setEntities] = useState<Json>({});

  const [session,setSession] = useState<Json>({})

  const [status,setStatus] = useState<Json>(()=>{
    return {
      inited:undefined,
      login:undefined
  }});

  const [global,getGlobal] = useRestGet('/admin/rest/global');


  useEffect(() => {

    getGlobal();
    
  }, []);


  useEffect(() => {
 
    console.log('data:' + JSON.stringify(data));

    if (global){

      console.log('global:' + JSON.stringify(global));

      let tmp:Json = {};

      Object.keys(global).map(k=>{
        console.log('key:' + k);
        let list:[Json] = global[k];

        let items:Json = {};

        list.map(e=>{
          items[e.uuid] = e;
        });

        tmp[k] = items;
      });


      setEntities({...tmp});

      console.log('entities: ' + JSON.stringify(entities));
    }

    if (data && global ){

      setStatus({...status,inited:true});
       
    }

    
  }, [data,global]);


  if (!status.inited){
    return <>Loading</>
  }


  return (
    <AppContext.Provider value={{ data, setData ,status,setStatus,session,setSession,entities,setEntities }}>
    <IonApp>
      <IonReactRouter basename='admin'>
        <IonSplitPane contentId="main" style={{
          "--side-width":"224px",
          "--side-min-width":"192px"
        }}>
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            <ProtectedRoute path="/home" auth={status.login} redirect="/login" exact={true}>
              <Home />
            </ProtectedRoute>

            {
              magimeta.route_pages.map((key:string)=>{
                return <ProtectedRoute key={key} path={'/' + key} auth={status.login} redirect="/login" exact={true}>
                <GenericList meta={magimeta.pages[key as keyof typeof magimeta.pages]} />
              </ProtectedRoute>;
              })
            }

            <ProtectedRoute path="/tool" auth={status.login} redirect="/login" exact={true}>
              <Tool />
            </ProtectedRoute>

            <Route path="/page/:name" exact={true}>
              <Page />
            </Route>
            
          </IonRouterOutlet>
        </IonSplitPane>
        <Route path="/login" exact={true}>
          <Login />
        </Route>
        <Route path="/logout" exact={true}>
          <Logout />
        </Route>

      </IonReactRouter>
    </IonApp>
    </AppContext.Provider>
  );
};

export default App;
